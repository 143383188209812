<template>
  <div class="container" @click="mapClicked">
    <img v-svg-inline class="mapSVG" src="./map.svg" alt="svg image" />
  </div>
</template>

<style >

.container{
      outline:none !important;

}


.mapSVG {
  margin: 50px 50px 150px;
  outline:none !important;
  height: 60vh;
}

</style> 
  
<script>
export default {
  name: "MapView",
  props: ["currentQuestion"],
  mounted() {
    let svgloader = document.createElement("script");
    svgloader.setAttribute("src", "//unpkg.com/vue-svg-inline-plugin");
    document.head.appendChild(svgloader);
  },
  methods: {
    mapClicked(event) {
      var path = event.path || (event.composedPath && event.composedPath());
      if (path[0].nodeName == "text") {
        county = path[3].id;
      } else {
        var county = path[2].id;
      }
      event.stopPropagation();
      if (county != "") {
        this.$store.dispatch("countySelected", {
          county: county,
        });
        this.$store.dispatch("updateQuestion", this.currentQuestion.id + 1);
      }
    },
  },
};
</script>

