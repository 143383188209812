var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "q-page",
    {},
    [
      _vm.currentQuestion
        ? _c(
            "div",
            [
              _c("div", { staticClass: "topSide" }, [
                _c("div", { staticClass: "row justify-between items-center" }, [
                  _vm.language == "EN"
                    ? _c(
                        "div",
                        { staticClass: "col-6 q-pa-sm" },
                        [
                          this.currentQuestion.id == 1
                            ? _c("q-btn", {
                                attrs: {
                                  color: "secondary",
                                  flat: "",
                                  label: "BACK",
                                  icon: "arrow_back_ios",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.goBack()
                                  },
                                },
                              })
                            : this.currentQuestion.id != 1
                            ? _c("q-btn", {
                                attrs: {
                                  color: "secondary",
                                  flat: "",
                                  label: "BACK",
                                  icon: "arrow_back_ios",
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.$store.dispatch(
                                      "updateQuestion",
                                      _vm.previousQuestionID
                                    )
                                    _vm.$store.dispatch("popLastQuestionID")
                                    _vm.$store.dispatch(
                                      "removeSavedData",
                                      _vm.previousQuestionID
                                    )
                                  },
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.language == "ES"
                    ? _c(
                        "div",
                        { staticClass: "col-6 q-pa-sm" },
                        [
                          this.currentQuestion.id == 1
                            ? _c("q-btn", {
                                attrs: {
                                  color: "secondary",
                                  flat: "",
                                  label: "REGRESAR",
                                  icon: "arrow_back_ios",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.goBack()
                                  },
                                },
                              })
                            : this.currentQuestion.id != 1
                            ? _c("q-btn", {
                                attrs: {
                                  color: "secondary",
                                  flat: "",
                                  label: "REGRESAR",
                                  icon: "arrow_back_ios",
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.$store.dispatch(
                                      "updateQuestion",
                                      _vm.previousQuestionID
                                    )
                                    _vm.$store.dispatch("popLastQuestionID")
                                    _vm.$store.dispatch(
                                      "removeSavedData",
                                      _vm.previousQuestionID
                                    )
                                  },
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.language == "EN"
                    ? _c(
                        "div",
                        { staticClass: "col-6 q-pa-sm" },
                        [
                          _c("q-btn", {
                            staticStyle: { float: "right" },
                            attrs: {
                              color: "secondary",
                              flat: "",
                              label: "EXIT",
                              "icon-right": "close",
                            },
                            on: { click: _vm.exit },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.language == "ES"
                    ? _c(
                        "div",
                        { staticClass: "col-6 q-pa-sm" },
                        [
                          _c("q-btn", {
                            staticStyle: { float: "right" },
                            attrs: {
                              color: "secondary",
                              flat: "",
                              label: "SALIDA",
                              "icon-right": "close",
                            },
                            on: { click: _vm.exit },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.currentQuestion.category != null
                    ? _c("div", { staticClass: "col-12" }, [
                        _c(
                          "h4",
                          {
                            staticClass:
                              "foundationFont text-center q-pt-lg text-weight-bolder fit",
                            staticStyle: { color: "#00667e" },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.currentQuestion.category) +
                                "\n          "
                            ),
                          ]
                        ),
                        _c("div", { attrs: { id: "borderBottom" } }),
                      ])
                    : _vm._e(),
                ]),
                _c("div", { staticClass: "row justify-center items-center" }, [
                  _c(
                    "div",
                    {
                      key: _vm.currentQuestion.question,
                      staticClass: "col-12 flex flex-center",
                    },
                    [
                      _vm.currentQuestion.id < 4
                        ? _c("div", { staticClass: "q-pt-xl q-mt-xl" })
                        : _vm._e(),
                      _vm.currentQuestion
                        ? _c(
                            "p",
                            {
                              staticClass:
                                "text-center text-weight-medium questionMobile q-px-md q-pt-md",
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.currentQuestion.question) +
                                  "\n            "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.currentQuestion.tip
                        ? _c("q-btn", {
                            key: _vm.currentQuestion.tip,
                            staticStyle: { "font-size": "1rem" },
                            attrs: {
                              color: "primary",
                              flat: "",
                              icon: "information",
                            },
                            on: {
                              click: function ($event) {
                                _vm.tip = true
                              },
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("div", { staticClass: "bottomSide" }, [
                _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    _vm.current_answers.length <= 3
                      ? _c(
                          "transition-group",
                          {
                            attrs: {
                              mode: "out-in",
                              "leave-class": "animated disappear",
                              "enter-active-class": "animated fadeInUp",
                              "leave-active-class": "animated fadeOutDown",
                            },
                          },
                          _vm._l(_vm.current_answers, function (answer) {
                            return _c(
                              "div",
                              {
                                key: answer.id,
                                staticClass: "flex flex-center q-py-md q-px-md",
                                staticStyle: { height: "14vh" },
                              },
                              [
                                _c(
                                  "q-btn",
                                  {
                                    ref: "answer",
                                    refInFor: true,
                                    staticClass:
                                      "shadow-0 q-my-md mybtnMobile fit",
                                    attrs: {
                                      disable: _vm.isDisabled,
                                      ripple: { center: true },
                                      href: "#",
                                      "no-caps": "",
                                      rounded: "",
                                      color: "primary",
                                      align: "left",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleTouch(answer)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "row items-center fit" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "col q-px-xs" },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  "font-size": "2.5vh",
                                                },
                                              },
                                              [_vm._v(_vm._s(answer.answer))]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          }),
                          0
                        )
                      : _c("div", { key: "county", staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col" },
                            [
                              _c("map-view-mobile", {
                                attrs: { currentQuestion: _vm.currentQuestion },
                              }),
                            ],
                            1
                          ),
                        ]),
                  ],
                  1
                ),
              ]),
              _c(
                "q-dialog",
                {
                  model: {
                    value: _vm.tip,
                    callback: function ($$v) {
                      _vm.tip = $$v
                    },
                    expression: "tip",
                  },
                },
                [
                  _c(
                    "q-card",
                    [
                      _c(
                        "q-card-section",
                        { staticClass: "row items-center" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "q-pa-md text-center mobileModalFont",
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.currentQuestion.tip) +
                                  "\n          "
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c("q-separator", {
                        attrs: { color: "secondary", inlet: "" },
                      }),
                      _c(
                        "q-card-actions",
                        { attrs: { align: "center" } },
                        [
                          _vm.language == "EN"
                            ? _c("q-btn", {
                                directives: [
                                  {
                                    name: "close-popup",
                                    rawName: "v-close-popup",
                                  },
                                ],
                                attrs: {
                                  size: "1rem",
                                  flat: "",
                                  label: "Continue",
                                  color: "primary",
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.tip = false
                                  },
                                },
                              })
                            : _vm._e(),
                          _vm.language == "ES"
                            ? _c("q-btn", {
                                directives: [
                                  {
                                    name: "close-popup",
                                    rawName: "v-close-popup",
                                  },
                                ],
                                attrs: {
                                  size: "1rem",
                                  flat: "",
                                  label: "Continuar",
                                  color: "primary",
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.tip = false
                                  },
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "q-dialog",
                {
                  attrs: { persistent: "" },
                  model: {
                    value: _vm.helping,
                    callback: function ($$v) {
                      _vm.helping = $$v
                    },
                    expression: "helping",
                  },
                },
                [
                  _vm.language == "EN"
                    ? _c(
                        "q-card",
                        [
                          _c(
                            "q-card-section",
                            { staticClass: "row items-center" },
                            [
                              _c("span", { staticClass: "q-pa-xs" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "text-center mobileModalFont",
                                  },
                                  [
                                    _vm._v(
                                      "\n              You are trying to find information for someone else. Please\n              answer the rest of these questions as if you are that person.\n            "
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                          _c("q-separator", {
                            attrs: { color: "secondary", inlet: "" },
                          }),
                          _c(
                            "q-card-actions",
                            { attrs: { align: "center" } },
                            [
                              _c("q-btn", {
                                directives: [
                                  {
                                    name: "close-popup",
                                    rawName: "v-close-popup",
                                  },
                                ],
                                attrs: {
                                  size: "1rem",
                                  flat: "",
                                  label: "Continue",
                                  color: "primary",
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.helping = false
                                    _vm.$store.dispatch(
                                      "updateQuestion",
                                      _vm.current_answers[0].nextQuestion
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.language == "ES"
                    ? _c(
                        "q-card",
                        [
                          _c(
                            "q-card-section",
                            { staticClass: "row items-center" },
                            [
                              _c("span", { staticClass: "q-pa-md" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "text-center mobileModalFont",
                                  },
                                  [
                                    _vm._v(
                                      "\n              Está intentando encontrar información para otra persona. Responda el resto de estas preguntas como si fuera esa persona.\n            "
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                          _c("q-separator", {
                            attrs: { color: "secondary", inlet: "" },
                          }),
                          _c(
                            "q-card-actions",
                            { attrs: { align: "center" } },
                            [
                              _c("q-btn", {
                                directives: [
                                  {
                                    name: "close-popup",
                                    rawName: "v-close-popup",
                                  },
                                ],
                                attrs: {
                                  size: "1rem",
                                  flat: "",
                                  label: "Continuar",
                                  color: "primary",
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.helping = false
                                    _vm.$store.dispatch(
                                      "updateQuestion",
                                      _vm.current_answers[0].nextQuestion
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "q-dialog",
                {
                  attrs: { persistent: "" },
                  model: {
                    value: _vm.arizona,
                    callback: function ($$v) {
                      _vm.arizona = $$v
                    },
                    expression: "arizona",
                  },
                },
                [
                  _c(
                    "q-card",
                    [
                      _c(
                        "q-card-actions",
                        { attrs: { align: "right" } },
                        [
                          _c("q-btn", {
                            directives: [
                              { name: "close-popup", rawName: "v-close-popup" },
                            ],
                            attrs: {
                              flat: "",
                              label: "X",
                              size: "1rem",
                              color: "primary",
                            },
                            on: {
                              click: function ($event) {
                                _vm.arizona = false
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "q-card-section",
                        { staticClass: "row items-center" },
                        [
                          _vm.language == "EN"
                            ? _c(
                                "span",
                                {
                                  staticClass:
                                    "q-pa-md text-center mobileModalFont",
                                },
                                [
                                  _vm._v(
                                    "\n            The information within these resources were developed to help\n            crime victims in Arizona. If you need general legal information or\n            to apply for legal help, visit AZLawHelp.org. If you are\n            located outside of Arizona, visit LawHelp.org.\n            "
                                  ),
                                  _c("q-btn", {
                                    staticClass: "q-ma-md modalBtn",
                                    attrs: {
                                      label: "Go To AZLawHelp",
                                      color: "primary",
                                      size: "1rem",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.goToAZLawHelp()
                                      },
                                    },
                                  }),
                                  _c("q-btn", {
                                    staticClass: "q-ma-md modalBtn",
                                    attrs: {
                                      label: "Go To LawHelp",
                                      color: "primary",
                                      size: "1rem",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.goToLawHelp()
                                      },
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.language == "ES"
                            ? _c(
                                "span",
                                {
                                  staticClass:
                                    "q-pa-md text-center mobileModalFont",
                                },
                                [
                                  _vm._v(
                                    "\n            La información contenida en estos recursos se desarrolló para ayudar\n            víctimas de delitos en Arizona. Si necesita información legal general o\n            para solicitar ayuda legal, visite AZLawHelp.org. Si usted es\n            ubicado fuera de Arizona, visite LawHelp.org.\n            "
                                  ),
                                  _c("q-btn", {
                                    staticClass: "q-ma-md modalBtn",
                                    attrs: {
                                      label: "Go To AZLawHelp",
                                      color: "primary",
                                      size: "1rem",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.goToAZLawHelp()
                                      },
                                    },
                                  }),
                                  _c("q-btn", {
                                    staticClass: "q-ma-md modalBtn",
                                    attrs: {
                                      label: "Go To LawHelp",
                                      color: "primary",
                                      size: "1rem",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.goToLawHelp()
                                      },
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "q-dialog",
        {
          attrs: { persistent: "" },
          model: {
            value: _vm.safety,
            callback: function ($$v) {
              _vm.safety = $$v
            },
            expression: "safety",
          },
        },
        [
          _vm.language == "EN"
            ? _c(
                "q-card",
                [
                  _c("q-card-section", [
                    _c("div", { staticClass: "row no-wrap items-center" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "col text-h6 ellipsis text-bold mobileModalFont",
                        },
                        [_vm._v("Contact 911")]
                      ),
                    ]),
                  ]),
                  _c("q-card-section", { staticClass: "q-pt-none" }, [
                    _c("div", { staticClass: "mobileModalFont" }, [
                      _vm._v(
                        "\n          Please call 911 if you feel you are in danger or need help right\n          away.\n        "
                      ),
                    ]),
                    _c("div", { staticClass: "text-caption text-grey" }),
                  ]),
                  _c("q-separator"),
                  _c(
                    "q-card-actions",
                    { attrs: { align: "center" } },
                    [
                      _c("q-btn", {
                        directives: [
                          { name: "close-popup", rawName: "v-close-popup" },
                        ],
                        attrs: { flat: "", color: "primary", label: "Close" },
                        on: {
                          click: function ($event) {
                            _vm.safety = false
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.language == "ES"
            ? _c(
                "q-card",
                [
                  _c("q-card-section", [
                    _c("div", { staticClass: "row no-wrap items-center" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "col text-h6 ellipsis text-bold mobileModalFont",
                        },
                        [_vm._v("Contactar al 911")]
                      ),
                    ]),
                  ]),
                  _c("q-card-section", { staticClass: "q-pt-none" }, [
                    _c("div", { staticClass: "mobileModalFont" }, [
                      _vm._v(
                        "\n          Llame al 911 si siente que está en peligro o necesita ayuda\n          lejos.\n        "
                      ),
                    ]),
                    _c("div", { staticClass: "text-caption text-grey" }),
                  ]),
                  _c("q-separator", {
                    attrs: { color: "secondary", inlet: "" },
                  }),
                  _c(
                    "q-card-actions",
                    { attrs: { align: "center" } },
                    [
                      _c("q-btn", {
                        directives: [
                          { name: "close-popup", rawName: "v-close-popup" },
                        ],
                        attrs: { flat: "", color: "primary", label: "Cerrar" },
                        on: {
                          click: function ($event) {
                            _vm.safety = false
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "q-dialog",
        {
          attrs: { seamless: "" },
          model: {
            value: _vm.county,
            callback: function ($$v) {
              _vm.county = $$v
            },
            expression: "county",
          },
        },
        [
          _c(
            "q-card",
            [
              _c("q-card-section", [
                _c("div", { staticClass: "row no-wrap items-center" }, [
                  _c("div", { staticClass: "col text-h6 ellipsis" }, [
                    _vm._v(
                      "\n            If you aren’t sure which Arizona county you live in, click the\n            button below to find your county.”\n          "
                    ),
                  ]),
                ]),
              ]),
              _c("q-separator"),
              _c(
                "q-card-actions",
                { attrs: { align: "right" } },
                [
                  _c("q-btn", {
                    directives: [
                      { name: "close-popup", rawName: "v-close-popup" },
                    ],
                    attrs: {
                      flat: "",
                      color: "primary",
                      label: "Find My County",
                    },
                    on: {
                      click: function ($event) {
                        _vm.county = false
                      },
                    },
                  }),
                  _c("q-btn", {
                    directives: [
                      { name: "close-popup", rawName: "v-close-popup" },
                    ],
                    attrs: { flat: "", color: "primary", label: "Exit" },
                    on: {
                      click: function ($event) {
                        _vm.county = false
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }