<template>
  <span class="" style="height:120px;" @click="mapClicked">
    <img style="height: 100%; width: 100%;" v-svg-inline class="icon" src="./map.svg" alt="svg image" />
  </span>
</template>

<style>
  @media screen and (min-width: 577px) {
    .icon {
      height: 50vh !important;
    }
  }
</style>
  
<script>

export default {
  name: "MapViewMobile",
  props: ["currentQuestion"],
  mounted() {
     let svgloader = document.createElement('script')
      svgloader.setAttribute('src', '//unpkg.com/vue-svg-inline-plugin')
      document.head.appendChild(svgloader)
  },
  methods: {
    mapClicked(event) {
      var path = event.path || (event.composedPath && event.composedPath());
      var county;
       if(path[0].nodeName == "text"){
        county = path[3].id
      }
      else{
        county = path[2].id;
      }
      event.stopPropagation();
      if(county != ''){
      this.$store.dispatch("countySelected", {
        county: county,
      });
      this.$store.dispatch("updateQuestion", this.currentQuestion.id + 1);
      }
    },
  },
};
</script>

