var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container", on: { click: _vm.mapClicked } },
    [
      _c("img", {
        directives: [{ name: "svg-inline", rawName: "v-svg-inline" }],
        staticClass: "mapSVG",
        attrs: { src: require("./map.svg"), alt: "svg image" },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }