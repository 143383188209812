<template>
  <!-- Desktop/Tablet Layout -->
  <q-page class="">
    <div v-if="currentQuestion">
      <!-- Section to display  back arrow, category, and exit button-->
      <div class="topSide">
        <div class="row items-center justify-between">
          <!-- Back button in English-->
          <div class="col-3" v-if="language == 'EN'">
            <q-btn
              v-if="this.currentQuestion.id == 1"
              color="secondary"
              flat
              label="BACK"
              icon="arrow_back_ios"
              @click="goBack()"
            ></q-btn>

            <q-btn
              v-else-if="this.currentQuestion.id != 1"
              color="secondary"
              flat
              label="BACK"
              icon="arrow_back_ios"
              @click="
                $store.dispatch('updateQuestion', previousQuestionID);
                $store.dispatch('popLastQuestionID');
                $store.dispatch('removeSavedData', previousQuestionID);
              "
            ></q-btn>
          </div>
          <!-- Back button in Spanish -->
          <div class="col-3" v-if="language == 'ES'">
            <q-btn
              v-if="this.currentQuestion.id == 1"
              color="secondary"
              flat
              label="REGRESAR"
              icon="arrow_back_ios"
              @click="goBack()"
            ></q-btn>

            <q-btn
              v-else-if="this.currentQuestion.id != 1"
              color="secondary"
              flat
              label="REGRESAR"
              icon="arrow_back_ios"
              @click="
                $store.dispatch('updateQuestion', previousQuestionID);
                $store.dispatch('popLastQuestionID');
                $store.dispatch('removeSavedData', previousQuestionID);
              "
            ></q-btn>
          </div>
          <div class="col-6"></div>
          <!-- Exit button in English-->
          <div class="col-3" v-if="language == 'EN'">
            <q-btn
              class=""
              color="secondary"
              flat
              label="EXIT"
              icon-right="close"
              @click="exit"
              style="float: right"
            ></q-btn>
          </div>
          <!-- Exit button in Spanish-->
          <div class="col-3" v-if="language == 'ES'">
            <q-btn
              class=""
              color="secondary"
              flat
              label="SALIDA"
              icon-right="close"
              @click="exit"
              style="float: right"
            ></q-btn>
          </div>
          <div v-if="currentQuestion.category != null" class="col-12">
            <h4
              class="foundationFont text-center text-bold"
              style="color: #00667e; margin-bottom: 25px"
            >
              {{ currentQuestion.category }}
            </h4>
            <div id="borderBottom"></div>
          </div>
        </div>

        <!-- Question -->

        <div class="row q-mt-lg justify-center items-center" style="">
          <!-- <transition
          name="fadeInLeft"
          appear
          :duration="125"
          enter-active-class="animated fadeInLeft"
          mode="out-in"
          leave-active-class="animated fadeOutLeft"
        > -->
          <transition name="fadeInLeft" appear>
            <div
              :key="currentQuestion"
              class="col-12 col-lg-8 col-md-8 col-sm-12 col-xs-12"
              v-if="currentQuestion.question"
            >
              <p class="text-center questionDesktop" style="">
                {{ currentQuestion.question }}
                <q-btn
                  :key="currentQuestion.tip"
                  v-if="currentQuestion.tip"
                  color="primary"
                  flat
                  icon="information"
                  style="font-size: 1.25rem"
                  @click="tip = true"
                ></q-btn>
              </p>
            </div>
          </transition>
        </div>
      </div>

      <div class="row bottomSide">
        <div
          class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 answersPosition"
          :key="current_answers"
        >
          <!-- <transition
            appear
            name="fadeInRight"
            v-if="current_answers.length <= 3"
            enter-active-class="animated fadeInRight"
            mode="out-in"
            leave-active-class="animated fadeOutRight"
          > -->
          <transition
            appear
            name="fadeInRight"
            v-if="current_answers.length <= 3"
          >
            <!-- If the question has less than 3 answers -->
            <div class="flex-container">
              <div
                :key="answer.id"
                v-for="answer in current_answers"
                class="text-center flex-child"
                style="margin: 10px"
              >
                <q-btn
                  @click="
                    $emit('answerClicked', answer);
                    handleClick(answer);
                  "
                  class="q-ma-sm myIntro full-width"
                  href="#"
                  size="2.5vh"
                  no-caps
                  color="primary"
                >
                  {{ answer.answer }}
                </q-btn>
              </div>
            </div>
          </transition>

          <transition
            appear
            name="fadeInRight"
            v-else-if="current_answers.length == 8"
            enter-active-class="animated fadeInRight"
            mode="out-in"
            leave-active-class="animated fadeOutRight"
          >
            <div class="row justify-center items-center q-pl-xl">
              <div
                :key="answer.id"
                v-for="answer in current_answers"
                class="col-3 q-pa-xs q-pl-md"
              >
                <q-btn
                  @click="
                    answer.selected = !answer.selected;
                    $emit('answerClicked', answer);
                  "
                  class="shadow-0 q-ma-xs victimBtn"
                  :class="[{ selected: answer.selected }]"
                  href="#"
                  no-caps
                  rounded
                  align="left"
                >
                  <div class="row">
                    <h3 style="font-size: 24px" class="q-px-lg">
                      <q-icon
                        class="answerBtn q-pr-lg"
                        :name="answer.icon"
                      ></q-icon>
                      {{ answer.answer }}
                    </h3>
                  </div>
                </q-btn>
              </div>

              <div class="row q-pt-md" key="continue">
                <div class="col">
                  <q-btn
                    @click="handleClick(current_answers[0])"
                    class="shadow-7"
                    rounded
                    size="2rem"
                    color="accent"
                    align="center"
                    label="Continue"
                  ></q-btn>
                </div>
              </div>
            </div>
          </transition>

          <!-- County Buttons -->

          <transition
            v-else
            appear
            name="fadeInRight"
            enter-active-class="animated fadeInRight"
            mode="out-in"
            leave-active-class="animated fadeOutRight"
          >
            <map-view
              class="map q-py-lg text-center"
              :currentQuestion="currentQuestion"
              @countyPicked="handleCounty"
            ></map-view>
          </transition>
        </div>
      </div>

      <!-- Tip Modal -->
      <q-dialog v-model="tip" persistent>
        <q-card class="modalCard">
          <q-card-section class="row items-center">
            <span class="q-pa-xs">
              <h5 class="text-center" style="color: #00667e">
                {{ currentQuestion.tip }}
              </h5>
            </span>
          </q-card-section>
          <q-separator color="secondary" inset />
          <q-card-actions align="center" v-if="language == 'EN'">
            <q-btn
              size="1.25rem"
              label="Continue"
              flat
              color="primary"
              v-close-popup
              @click="tip = false"
            />
          </q-card-actions>
          <q-card-actions align="center" v-if="language == 'ES'">
            <q-btn
              size="1.25rem"
              label="Continuar"
              flat
              color="primary"
              v-close-popup
              @click="tip = false"
            />
          </q-card-actions>
        </q-card>
      </q-dialog>

      <!-- I am here to Help Modal -->
      <q-dialog v-model="helping" persistent>
        <!-- English Version -->
        <q-card v-if="language == 'EN'" class="modalCard">
          <q-card-section class="row items-center">
            <span class="q-pa-xs">
              <h5 class="text-center" style="color: #00667e">
                You are trying to find information for someone else. Please
                answer the rest of these questions as if you are that person.
              </h5>
            </span>
          </q-card-section>
          <q-separator color="secondary" inset />
          <q-card-actions align="center">
            <q-btn
              size="1.25rem"
              label="Continue"
              flat
              color="primary"
              v-close-popup
              @click="
                helping = false;
                $store.dispatch(
                  'updateQuestion',
                  current_answers[0].nextQuestion
                );
              "
            />
          </q-card-actions>
        </q-card>
        <!-- Spanish Version -->
        <q-card v-if="language == 'ES'" class="modalCard">
          <q-card-section class="row items-center">
            <span class="q-pa-xs">
              <h5 class="text-center" style="color: #00667e">
                Está intentando encontrar información para otra persona.
                Responda el resto de estas preguntas como si fuera esa persona.
              </h5>
            </span>
          </q-card-section>
          <q-separator color="secondary" inset />
          <q-card-actions align="center">
            <q-btn
              size="1.25rem"
              label="Continuar"
              flat
              color="primary"
              v-close-popup
              @click="
                helping = false;
                $store.dispatch(
                  'updateQuestion',
                  current_answers[0].nextQuestion
                );
              "
            />
          </q-card-actions>
        </q-card>
      </q-dialog>

      <!-- Are you in Arizona Modal -->
      <q-dialog v-model="arizona" persistent>
        <q-card class="modalCard">
          <q-card-actions align="right">
            <q-btn
              class
              label="X"
              flat
              size="1.5rem"
              color="primary"
              v-close-popup
              @click="arizona = false"
            />
          </q-card-actions>
          <q-card-section class="row items-center">
            <!-- English Version -->
            <span
              v-if="language == 'EN'"
              class="q-pa-none text-center"
              style="color: #00667e"
            >
              <h5 style="margin-top: auto">
                The information within these resources were developed to help
                crime victims in Arizona. If you need general legal information
                or to apply for legal help, visit AZLawHelp.org. If you are
                located outside of Arizona, visit LawHelp.org.
              </h5>
              <q-btn
                class="q-ma-md modalBtn"
                label="Go To AZLawHelp"
                color="primary"
                size="1.25rem"
                @click="goToAZLawHelp()"
              ></q-btn>
              <q-btn
                class="q-ma-md modalBtn"
                label="Go To LawHelp"
                color="primary"
                size="1.25rem"
                @click="goToLawHelp()"
              ></q-btn>
            </span>
            <!-- Are You In Ariona - Spanish -->
            <span
              v-if="language == 'ES'"
              class="q-pa-none text-center"
              style="color: #00667e"
            >
              <h5 style="margin-top: auto">
                La información contenida en estos recursos se desarrolló para
                ayudar a las víctimas de delitos en Arizona. Si necesita
                información legal general o para solicitar ayuda legal, visite
                AZLawHelp.org. Si usted es ubicado fuera de Arizona, visite
                LawHelp.org.
              </h5>
              <q-btn
                class="q-ma-md modalBtn"
                label="Visite AZLawHelp"
                color="primary"
                size="1.25rem"
                @click="goToAZLawHelp()"
              ></q-btn>
              <q-btn
                class="q-ma-md modalBtn"
                label="Visite LawHelp"
                color="primary"
                size="1.25rem"
                @click="goToLawHelp()"
              ></q-btn>
            </span>
          </q-card-section>
        </q-card>
      </q-dialog>
    </div>
    <!-- Safety modal in English -->
    <q-dialog v-model="safety" persistent>
      <q-card v-if="language == 'EN'" class="modalCard">
        <q-card-section class="row items-center">
          <div
            class="q-pa-none q-ma-none text-center"
            style="color: #00667e; margin: auto"
          >
            <h5 class="text-bold" style="height: 0px">Contact 911</h5>
          </div>
          <div class="q-pa-none q-ma-none">
            <h5 class="text-center" style="color: #00667e">
              Please call 911 if you feel you are in danger or need help right
              away.
            </h5>
          </div>
        </q-card-section>
        <q-separator color="secondary" inset />

        <q-card-actions align="center">
          <q-btn
            v-close-popup
            flat
            color="primary"
            label="Close"
            size="1rem"
            @click="helping = false"
          />
        </q-card-actions>
      </q-card>
      <!-- Safety modal in Spanish -->
      <q-card v-else-if="language == 'ES'" class="modalCard">
        <q-card-section class="row items-center">
          <div
            class="q-pa-none q-ma-none text-center"
            style="color: #00667e; margin: auto"
          >
            <h5 class="text-bold" style="height: 0px">Contactar al 911</h5>
          </div>
          <div class="q-pa-none q-ma-none">
            <h5 class="text-center" style="color: #00667e">
              Llame al 911 si siente que está en peligro o necesita ayuda lejos.
            </h5>
          </div>
        </q-card-section>
        <q-separator color="secondary" inset />

        <q-card-actions align="center">
          <q-btn
            v-close-popup
            flat
            color="primary"
            label="Cerrar"
            size="1rem"
            @click="helping = false"
          />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </q-page>
</template>

<script>
import axios from "axios";
import MapView from "./azMap/MapView.vue";

import { mapGetters } from "vuex";

export default {
  components: { MapView },
  name: "DesktopView",
  props: [
    "currentQuestion",
    "categories",
    "current_category",
    "current_answers",
    "language",
  ],
  data() {
    return {
      streetAddress: "",
      cityST: "",
      helping: false, // display helping self or others modal if true
      arizona: false, // display arizona citizen modal if true
      safety: false, // display safety/911 modal if true
      tip: false,
      countyChosen: "",
      icons: {
        // Icons that represent each category
        Safety: "security",
        Finance: "monetization_on",
        Housing: "domain",
        Family: "child_friendly",
        FinishingUp: "person_pin",
      },
    };
  },
  computed: {
    ...mapGetters({
      county: "getCounty",
      previousQuestionID: "getPreviousQuestionId",
    }),
  },
  methods: {
    goBack() {
      window.history.go(-1);
    },
    exit() {
      window.open("http://www.google.com", "_self");
    },

    goToAZLawHelp() {
      window.open("https://azlawhelp.org/", "_blank");
    },
    goToLawHelp() {
      window.open("https://www.lawhelp.org/", "_blank");
    },
    findCounty() {
      axios
        .get(
          `https://www.yaddress.net/api/Address?AddressLine1=${encodeURI(
            this.streetAddress
          )}&AddressLine2=${encodeURI(this.cityST)}&UserKey=`
        )
        .then((response) => {
          this.countyLookup = response.data.County;
        });
    },
    onReset() {
      (this.streetAddress = ""), (this.cityST = ""), (this.countyLookup = "");
    },
    handleClick(answer) {
      this.$store.dispatch("updatePreviousQuestionID", this.currentQuestion.id);
      if (!answer.modal) {
        this.$store.dispatch("updateQuestion", answer.nextQuestion);
      }
      if (answer.modal == "helping") {
        this.helping = true;
      }

      if (answer.modal == "arizona") {
        this.arizona = true;
      }

      if (answer.modal == "county") {
        this.county = true;
      }

      if (answer.modal == "safety") {
        this.safety = true;
        this.$store.dispatch("updateQuestion", answer.nextQuestion);
      }

      if (answer.nextQuestion == "end") {
        this.$router.push("report");
      }
    },
    countySelected(answer) {
      this.$store.dispatch("countySelected", {
        county: answer.answer,
      });
    },
  },
};
</script>

<style >
.foundationFont {
  font-family: "Open Sans", sans-serif;
}

.topSide {
  background-color: #fff;
}

.bottomSide {
  background-color: #82b8c4;
  background: border-box;
}

.questionDesktop {
  font-size: 4vmin;
  color: #00667e;
  font-family: "Open Sans", sans-serif;
  line-height: 1.25;
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.myIntro:hover {
  background-color: #468b9f !important;
  color: white;
}

.myIntro {
  height: 100%;
  border-radius: 20px;
}

.answerBtn {
  color: black;
}

.myCounty:hover {
  background-color: #468b9f !important;
  color: white;
}

.myCounty {
  color: black;
  background-color: #fff;
  border-style: solid;
  border-color: #fff;
  border-width: 2px;
  width: 100%;
  height: 7.5vh;
}

.mybtn:hover {
  background-color: #468b9f !important;
  color: white;
}

.mybtn {
  color: black;
  background-color: #fff;
  border-style: solid;
  border-color: #fff;
  border-width: 2px;
  height: 100%;
  width: 80%;
}

.answersPosition {
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.victimBtn:hover {
  background-color: #468b9f !important;
  color: white;
}

.victimBtn {
  color: black;
  background-color: #fff;
  border-style: solid;
  border-color: #fff;
  border-width: 4px;
  width: 100%;
  height: 100%;
}

#borderBottom {
  width: 70%;
  margin: auto;
  border-top: 1px solid #0c667d;
}

.flex-container {
  display: flex;
}

.flex-child {
  flex: 1;
}

.selected {
  background-color: #468b9f !important;
}

.modalBtn {
  border-radius: 10px;
}

.modalCard {
  border-radius: 15px !important;
}

.map {
  margin: auto;
}

.answersTransition-enter-active,
.answersTransition-leave-active {
  transition: opacity 0.5s ease-in-out, transform 0.5s ease;
}

.answersTransition-enter-active {
  transition-delay: 0.5s;
}

.answersTransition-enter,
.answersTransition-leave-to {
  opacity: 0;
}

.answersTransition-enter-to,
.answersTransition-leave {
  opacity: 1;
}
</style>
  