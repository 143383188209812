 <template>
  <!-- Mobile Design -->
  <q-page class="">
    <div v-if="currentQuestion">
      <div class="topSide">
        <!-- Section to display back arrow, exit, and question category -->
        <div class="row justify-between items-center">
          <!-- Back button in English -->
          <div class="col-6 q-pa-sm" v-if="language == 'EN'">
            <q-btn
              v-if="this.currentQuestion.id == 1"
              color="secondary"
              flat
              label="BACK"
              icon="arrow_back_ios"
              @click="goBack()"
            ></q-btn>

            <q-btn
              v-else-if="this.currentQuestion.id != 1"
              color="secondary"
              flat
              label="BACK"
              icon="arrow_back_ios"
              @click="
                $store.dispatch('updateQuestion', previousQuestionID);
                $store.dispatch('popLastQuestionID');
                $store.dispatch('removeSavedData', previousQuestionID);
              "
            ></q-btn>
          </div>
          <!-- Back button in Spanish -->
          <div class="col-6 q-pa-sm" v-if="language == 'ES'">
            <q-btn
              v-if="this.currentQuestion.id == 1"
              color="secondary"
              flat
              label="REGRESAR"
              icon="arrow_back_ios"
              @click="goBack()"
            ></q-btn>

            <q-btn
              v-else-if="this.currentQuestion.id != 1"
              color="secondary"
              flat
              label="REGRESAR"
              icon="arrow_back_ios"
              @click="
                $store.dispatch('updateQuestion', previousQuestionID);
                $store.dispatch('popLastQuestionID');
                $store.dispatch('removeSavedData', previousQuestionID);
              "
            ></q-btn>
          </div>
          <!-- <div class="col-4"></div> -->
          <!-- Exit button in English-->
          <div class="col-6 q-pa-sm" v-if="language == 'EN'">
            <q-btn
              class=""
              color="secondary"
              flat
              label="EXIT"
              icon-right="close"
              @click="exit"
              style="float: right"
            ></q-btn>
          </div>
          <!-- Exit button in Spanish-->
          <div class="col-6 q-pa-sm" v-if="language == 'ES'">
            <q-btn
              class=""
              color="secondary"
              flat
              label="SALIDA"
              icon-right="close"
              @click="exit"
              style="float: right"
            ></q-btn>
          </div>
          <!-- Display question category during question section -->
          <div v-if="currentQuestion.category != null" class="col-12">
            <h4
              class="foundationFont text-center q-pt-lg text-weight-bolder fit"
              style="color: #00667e;"
            >
              {{ currentQuestion.category }}
            </h4>
            <div id="borderBottom"></div>
          </div>
        </div>

        <!-- Question -->
        <div class="row justify-center items-center">
            <div
              :key="currentQuestion.question"
              class="col-12 flex flex-center"
            >
            <div v-if="currentQuestion.id < 4" class="q-pt-xl q-mt-xl">

            </div>
              <p
                class="text-center text-weight-medium questionMobile q-px-md q-pt-md"
                v-if="currentQuestion"
              >
                {{ currentQuestion.question }}
              </p>

              <q-btn
                :key="currentQuestion.tip"
                v-if="currentQuestion.tip"
                color="primary"
                flat
                icon="information"
                style="font-size: 1rem"
                @click="tip = true"
              ></q-btn>
            </div>
        </div>
      </div>

      <div class="bottomSide">
        <div class="col-12">
          <transition-group
            mode="out-in"
            v-if="current_answers.length <= 3"
            leave-class="animated disappear"
            enter-active-class="animated fadeInUp"
            leave-active-class="animated fadeOutDown"
          >
            <!-- If the question has less than 4 answers -->
            <div
              :key="answer.id"
              v-for="answer in current_answers"
              style="height: 14vh"
              class="flex flex-center q-py-md q-px-md"
            >
              <q-btn
                @click="handleTouch(answer)"
                :disable="isDisabled"
                ref="answer"
                :ripple="{ center: true }"
                class="shadow-0 q-my-md mybtnMobile fit"
                href="#"
                no-caps
                rounded
                color="primary"
                align="left"
              >
                <div class="row items-center fit">
                  <!--<div class>
                    <q-icon class :name="answer.icon"></q-icon>
                  </div> -->
                  <div class="col q-px-xs">
                    <span style="font-size: 2.5vh" class>{{
                      answer.answer
                    }}</span>
                  </div>
                </div>
              </q-btn>
            </div>
          </transition-group>

          <div class="row" key="county" style="" v-else>
            <div class="col">
              <map-view-mobile
                :currentQuestion="currentQuestion"
              ></map-view-mobile>
            </div>
          </div>
        </div>
      </div>

      <!-- Tip Modal -->
      <q-dialog v-model="tip">
        <q-card>
          <q-card-section class="row items-center">
            <div class="q-pa-md text-center mobileModalFont">
              {{ currentQuestion.tip }}
            </div>
          </q-card-section>
          <q-separator color="secondary" inlet />
          <q-card-actions align="center">
            <q-btn v-if="language == 'EN'"
              size="1rem"
              flat
              label="Continue"
              color="primary"
              v-close-popup
              @click="tip = false"
            />
            <q-btn v-if="language == 'ES'"
              size="1rem"
              flat
              label="Continuar"
              color="primary"
              v-close-popup
              @click="tip = false"
            />
          </q-card-actions>
        </q-card>
      </q-dialog>

      <!-- I am here to Help Modal -->
      <q-dialog v-model="helping" persistent>
        <!-- English version -->
        <q-card v-if="language == 'EN'">
          <q-card-section class="row items-center">
            <span class="q-pa-xs">
              <div class="text-center mobileModalFont">
                You are trying to find information for someone else. Please
                answer the rest of these questions as if you are that person.
              </div>
            </span>
          </q-card-section>
          <q-separator color="secondary" inlet />
          <q-card-actions align="center">
            <q-btn
              size="1rem"
              flat
              label="Continue"
              color="primary"
              v-close-popup
              @click="
                helping = false;
                $store.dispatch(
                  'updateQuestion',
                  current_answers[0].nextQuestion
                );
              "
            />
          </q-card-actions>
        </q-card>
        <!-- Spanish version -->
        <q-card v-if="language == 'ES'">
          <q-card-section class="row items-center">
            <span class="q-pa-md">
              <div class="text-center mobileModalFont">
                Está intentando encontrar información para otra persona. Responda el resto de estas preguntas como si fuera esa persona.
              </div>
            </span>
          </q-card-section>
          <q-separator color="secondary" inlet />
          <q-card-actions align="center">
            <q-btn
              size="1rem"
              flat
              label="Continuar"
              color="primary"
              v-close-popup
              @click="
                helping = false;
                $store.dispatch(
                  'updateQuestion',
                  current_answers[0].nextQuestion
                );
              "
            />
          </q-card-actions>
        </q-card>
      </q-dialog>

      <!-- Are you in Arizona Modal -->
      <q-dialog v-model="arizona" persistent>
        <q-card>
          <q-card-actions align="right">
            <q-btn
              flat
              label="X"
              size="1rem"
              color="primary"
              v-close-popup
              @click="arizona = false"
            />
          </q-card-actions>
          <q-card-section class="row items-center">
            <!-- English -->
            <span v-if="language == 'EN'" class="q-pa-md text-center mobileModalFont">
              The information within these resources were developed to help
              crime victims in Arizona. If you need general legal information or
              to apply for legal help, visit AZLawHelp.org. If you are
              located outside of Arizona, visit LawHelp.org.
              <q-btn
                class="q-ma-md modalBtn"
                label="Go To AZLawHelp"
                color="primary"
                size="1rem"
                @click="goToAZLawHelp()"
              ></q-btn>
              <q-btn
                class="q-ma-md modalBtn"
                label="Go To LawHelp"
                color="primary"
                size="1rem"
                @click="goToLawHelp()"
              ></q-btn>
            </span>
            <!-- Spanish -->
            <span v-if="language == 'ES'" class="q-pa-md text-center mobileModalFont">
              La información contenida en estos recursos se desarrolló para ayudar
              víctimas de delitos en Arizona. Si necesita información legal general o
              para solicitar ayuda legal, visite AZLawHelp.org. Si usted es
              ubicado fuera de Arizona, visite LawHelp.org.
              <q-btn
                class="q-ma-md modalBtn"
                label="Go To AZLawHelp"
                color="primary"
                size="1rem"
                @click="goToAZLawHelp()"
              ></q-btn>
              <q-btn
                class="q-ma-md modalBtn"
                label="Go To LawHelp"
                color="primary"
                size="1rem"
                @click="goToLawHelp()"
              ></q-btn>
            </span>
          </q-card-section>
        </q-card>
      </q-dialog>
    </div>
    <!-- Safety Modal -->
    <q-dialog v-model="safety" persistent>
      <!-- English -->
      <q-card v-if="language == 'EN'">
        <q-card-section>
          <div class="row no-wrap items-center">
            <div class="col text-h6 ellipsis text-bold mobileModalFont">Contact 911</div>
          </div>
        </q-card-section>

        <q-card-section class="q-pt-none">
          <div class="mobileModalFont">
            Please call 911 if you feel you are in danger or need help right
            away.
          </div>
          <div class="text-caption text-grey"></div>
        </q-card-section>

        <q-separator />

        <q-card-actions align="center">
          <q-btn
            v-close-popup
            flat
            color="primary"
            label="Close"
            @click="safety = false"
          />
        </q-card-actions>
      </q-card>
       <!-- Spanish -->
      <q-card v-if="language == 'ES'">
        <q-card-section>
          <div class="row no-wrap items-center">
            <div class="col text-h6 ellipsis text-bold mobileModalFont">Contactar al 911</div>
          </div>
        </q-card-section>

        <q-card-section class="q-pt-none">
          <div class="mobileModalFont">
            Llame al 911 si siente que está en peligro o necesita ayuda
            lejos.
          </div>
          <div class="text-caption text-grey"></div>
        </q-card-section>

        <q-separator color="secondary" inlet/>

        <q-card-actions align="center">
          <q-btn
            v-close-popup
            flat
            color="primary"
            label="Cerrar"
            @click="safety = false"
          />
        </q-card-actions>
      </q-card>
    </q-dialog>

    <q-dialog v-model="county" seamless>
      <q-card>
        <q-card-section>
          <div class="row no-wrap items-center">
            <div class="col text-h6 ellipsis">
              If you aren’t sure which Arizona county you live in, click the
              button below to find your county.”
            </div>
          </div>
        </q-card-section>

        <q-separator />

        <q-card-actions align="right">
          <q-btn
            v-close-popup
            flat
            color="primary"
            label="Find My County"
            @click="county = false"
          />

          <q-btn
            v-close-popup
            flat
            color="primary"
            label="Exit"
            @click="county = false"
          />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </q-page>
</template>

<script>
import { mapGetters } from 'vuex';
import MapViewMobile from "./azMap/MapViewMobile.vue";

export default {
  name: "MobileView",
  components: { MapViewMobile },
  props: [
    "currentQuestion",
    "categories",
    "current_category",
    "current_answers",
    "language",
  ],
  data() {
    return {
      helping: false, // display helping self or others modal if true
      arizona: false, // display arizona citizen modal if true
      safety: false, // display safety/911 modal if true
      tip: false,
      county: false, // display safety/911 modal if true
      isDisabled: false,

      icons: {
        // Icons that represent each category
        Safety: "security",
        Finance: "monetization_on",
        Housing: "domain",
        Family: "child_friendly",
        FinishingUp: "person_pin",
      },
    };
  },
  computed: {
    ...mapGetters({
      previousQuestionID: "getPreviousQuestionId",
    }),
  },
  methods: {
    exit() {
      window.location = "http://www.google.com";
    },
    goBack() {
      window.history.go(-1);
    },

    goToAZLawHelp() {
      window.location = "https://AZlawhelp.org";
    },
    goToLawHelp() {
      window.location = "https://lawhelp.org";
    },
    handleClick(answer) {
      this.$store.dispatch("updatePreviousQuestionID", this.currentQuestion.id);
      if (!answer.modal) {
        this.$store.dispatch("updateQuestion", answer.nextQuestion);
      }
      if (answer.modal == "helping") {
        this.helping = true;
      }

      if (answer.modal == "arizona") {
        this.arizona = true;
      }

      if (answer.modal == "county") {
        this.county = true;
      }

      if (answer.modal == "safety") {
        this.safety = true;
        this.$store.dispatch("updateQuestion", answer.nextQuestion);
      }

      if (answer.nextQuestion == "end") {
        this.$router.push("report");
      }
    },
    handleTouch(answer) {
      this.$emit("answerClicked", answer);
      this.handleClick(answer);
      this.isDisabled = true;
      setTimeout(() => {
        this.isDisabled = false;
      }, 250);
    },

    countySelected(answer) {
      this.$store.dispatch("countySelected", {
        county: answer.answer,
      });
    },

    handleVictimTouch(answer) {
      answer.selected = !answer.selected;
      this.$emit("answerClicked", answer);
      setTimeout(() => {
        this.isDisabled = false;
      }, 250);
    },
  },
};
</script>
<style  scoped>
.foundationFont {
  font-family: "Open Sans", sans-serif;
}

.topSide {
  background-color: #fff;
  height: 50vh;
}

.bottomSide {
  background-color: #82b8c4;
  height: 50vh;
}

.modalText {
  font-size: 2vh;
}

.questionMobile{
  color: #00667e;
  font-family: 'Open Sans', sans-serif;
  font-size: 2.5vh;
  line-height: 1.5;
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  width:90%;
}

.myCountyMobile {
  color: black;
  background-color: #00667e;
  border-style: solid;
  border-color: #00667e;
  border-width: 1x;
  border-radius: 15px;
  min-width: 100%;
  height: 7vh;
}

.mybtnMobile {
  color: black;
  background-color: #fff;
  border-style: solid;
  border-color: #00667e;
  border-width: 1px;
  border-radius: 20px;
}

.victimBtnMobile {
  color: black;
  background-color: #00667e;
  border-style: solid;
  border-color: #00667e;
  border-width: 1px;
  border-radius: 20px;
  width: 100%;
  height: 20vh;
}

.selected {
  background-color: #603f85 !important;
}

.disappear {
  display: none;
}

.answersPositionMobile {
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mobileModalFont {
  font-size: 1rem;
  color: #0c667d;
  font-family: 'Open Sans', sans-serif;
}

.q-card {
  border-radius: 15px !important;
}

.modalBtn {
  border-radius: 10px;
}
</style>